import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Styled, { keyframes } from 'styled-components'

import SeekerBar from '../../seeker-bar'
import TalentInfo from './talent-info'

import PlayerControls, { Controls, PlayButton } from '../player'
import PlaylistButton from '../playlistButton'
import VolumeControl from '../volumeControl'

import CloseIcon from '../../../icons/close-icon.svg'
// import FastforwardIcon from '../../../icons/fastforward-icon.svg'

const ControlsAndOther = Styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  justify-content: space-between;
  @media screen and (min-width: 576px) {
    justify-content: center;
  }
  @media screen and (min-width: 768px) {
    margin-left: ${57/16}rem;
  }
  ${Controls} {
    order: 3;
    width: auto;
    margin-right: ${8/16}rem;
    ${PlayButton} {
      border: none;
      @media screen and (min-width: 576px) {
        border: 1px solid white;
      }
      &:hover {
        background: #763b82;
      }
    }
    @media screen and (min-width: 576px) {
      margin-right: ${42/16}rem;
      order: initial;
    }
  }
`
const MainPlayerContainer = Styled.div`
  display: flex;
  background-color: #632A6D;
  position: relative;
  justify-content: space-between;
  padding: 0 ${20/16}rem;
  transition: all .3s ease-in-out;
  width: 100%;
  ${props => props.show ? `
    bottom: 0;
    opacity: 1;
    height: ${60/16}rem;
    
    @media screen and (min-width: 576px) {
      height: ${109/16}rem;
    }
    @media screen and (min-width: 768px) {
      padding: ${29/16}rem ${87/16}rem;
    }
  ` : `
    opacity: 0;
    bottom: -${109/16}rem;
    height: 0;
  `}
`
const rightJoinInMiddle = keyframes`
  0% {
    right: 0;
    width: 0;
    opacity: 1;
  }
   85% {
    right: 100%;
    width: 100%;
    opacity: .5;
  }
  100% {
    right: 200%;
    width: 100%;
    opacity: 0;
  }
`
const leftJoinInMiddle = keyframes`
  0% {
    left: 0;
    width: 0;
    opacity: 1;
  }
  85% {
    left: 100%;
    width: 100%;
    opacity: .5;
  }
  100% {
    left: 200%;
    width: 100%;
    opacity: 0;
  }
`
const ProgressBar = Styled.div`
  cursor:pointer;
  display: none;
  @media screen and (min-width: 576px) {
    display: block
  }
`
const LoadingBar = Styled.div`
  display: flex;
  width: 100%;
  height: ${8/16}rem;
  background-color: white;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.50);
  span {
    position: relative;
    width: 100%;
    overflow: hidden;
    &::before, &::after {
      content: '';
      width: 25%;
      height: ${8/16}rem;
      display: block;
      position: absolute;
      background-color: #FF6600;
      animation-duration: 1.2s;
      animation-timing-function: ease-in;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }
    &::before {
      animation-name: ${leftJoinInMiddle};
      left: 0;
    }
    &::after {
      right: 0;
      animation-name: ${rightJoinInMiddle};
    }
  }
`
const Name = Styled.div`
  display: flex;
  color: white;
  font-weight: 600;
  font-size: ${14/16}rem;
  letter-spacing: 4.59px;
  margin-bottom: 0;
  @media screen and (min-width: 576px) {
    margin-bottom: ${18/16}rem;
    font-size: ${18/16}rem;
  }
`
const MetaInfoWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 ${623/16}rem;
  @media screen and (min-width: 576px) {
    margin-left: ${40/16}rem;
  }
`
const TimeDuration = Styled.div`
  display: none;
  align-self: flex-end;
  letter-spacing: 1px;
  font-size: ${12/16}rem;
  font-weight: 600;
  color: white;
  margin-bottom: ${9/16}rem;
  @media screen and (min-width: 576px) {
    display: block;
  }
`
const NameAndTimeGroup = Styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const CloseMainPlayer = Styled.div`
  display: none;
  @media screen and (min-width: 576px) {
    display: block;
    position: absolute;
    padding: ${18/16}rem;
    top: 0;
    right: 0;
    cursor: pointer;
    &:hover #Voice-Reels-Demos---Player {
      stroke: rgba(255, 255, 255, 0.6);
    }
  }
`;
// const FFIcon = Styled(FastforwardIcon)`
//   ${props => props.hasPlaylistItem ? null : `fill: rgba(162, 141, 173, 0.50);`}
//   ${props => props.rewind ? `transform: rotate(180deg);` : null}
// `
// const Fastforward = Styled.div`
//   display: none;
//   @media screen and (min-width: 576px) {
//     display: flex;
//   }
// `
// const Rewind = Styled.div`
//   display: none;
//   padding-right: 15px;
//   @media screen and (min-width: 576px) {
//     display: flex;
//   }
// `
class MainPlayer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      src: '',
      timerInterval: null,
      talentInfoState: false,
    }
    this.audio = React.createRef()
    this.playlist = React.createRef()

    this.updateCurrentTime = this.updateCurrentTime.bind(this)
    this.onLoadedMetadata = this.onLoadedMetadata.bind(this)
    this.playing = this.playing.bind(this)
    this.stop = this.stop.bind(this)
    this.playPause = this.playPause.bind(this)
  }
  stop() {
    this.audio.current.pause()
    clearInterval(this.state.timerInterval)
    this.props.stopPlaying()
    this.props.updateCurrentTime(0)
  }
  play() {
    this.props.loading()
    this.audio.current.play().then(() => {
      this.props.isReady()
      this.props.startPlaying()
    }).catch( error => {
      this.props.loading()
      console.error(error)
    })
  }
  pause() {
    this.audio.current.pause()
    clearInterval(this.state.timerInterval)
    this.props.stopPlaying()
  }
  playPause() {
    this.props.playPauseHandler()
  }
  onLoadStart() {
    this.props.loading()
    if (this.props.isPlaying) {
      this.props.stopPlaying()
    }
  }
  updateCurrentTime() {
    this.props.updateCurrentTime(this.audio.current.currentTime)
  }
  onLoadedMetadata() {
    const {updateDuration} = this.props
    const {duration} = this.audio.current
    
    updateDuration(duration)
  }
  playing() {
    this.props.isReady()
    this.props.startPlaying()
    this.setState({
      timerInterval: setInterval(
        this.updateCurrentTime,
        500)
    })
  }
  timeLeft(t) {
    return Math.floor( t / 60 ) + ':' +
      ('0' + Math.floor( t % 60) ).slice(-2)
  }
  componentWillUnmount() {
    this.props.stopPlaying()
    clearInterval(this.state.timerInterval)
  }
  componentDidUpdate(prevProps) {
    const audio = this.audio.current
    const {isPlaying, trackInfo: { url }} = this.props
    if (url !== prevProps.trackInfo.url) {
      const src = url.replace(/https?:\/\/(www.|admin.)?noblecaplanabrams.c[a,o,m]+/g, 'https://admin.ncatalent.com');
      this.props.loading()
      this.setState({src})
      audio.load()
    }
    if (prevProps.isPlaying !== isPlaying) {
      if (isPlaying === true) {
        this.play()
      } else if (isPlaying === false) {
        this.pause()
      }
    }
  }
  render() {
    const {
      isPlaying,
      isLoading,
      trackInfo,
      duration,
      currentTime,

      playlist,
      playlistState,
      togglePlaylist,

      mainPlayerState,
      closePlayer,
    } = this.props

    return (
      <MainPlayerContainer show={mainPlayerState}>
        <CloseMainPlayer onClick={closePlayer}>
          <CloseIcon />
        </CloseMainPlayer>
        <PlaylistButton state={playlistState} onClick={togglePlaylist} count={playlist.length}/>
        <audio
          ref={this.audio}
          src={this.state.src}
          onLoadedMetadata={this.onLoadedMetadata}
          onPlaying={this.playing}
          onEnded={this.stop}
          preload="meta"
          autoPlay={true}
        >
          <track kind="metadata"/>
          <p>Your browser does not support the
          <code>audio</code> element.</p>
        </audio>
        <ControlsAndOther>
          {/* <Rewind>
            <FFIcon rewind="true"/>
          </Rewind> */}
          <PlayerControls onClick={this.playPause} isPlaying={isPlaying} mainPlayer/>
          {/* <Fastforward>
            <FFIcon />
          </Fastforward> */}
          
          <MetaInfoWrapper>
            <NameAndTimeGroup>
              <Name>
                <TalentInfo trackInfo={trackInfo} />
                {trackInfo.first_name} {trackInfo.last_name}
              </Name>
              <TimeDuration>
              { this.timeLeft(currentTime) }&nbsp;/&nbsp;
                { Math.floor( duration / 60 ) }:{ ('0' + Math.floor( duration % 60) ).slice(-2) }
              </TimeDuration>
            </NameAndTimeGroup>
            <ProgressBar>
              { isLoading ? (
                <LoadingBar>
                  <span></span>
                </LoadingBar>
              ) : (
                <SeekerBar
                  audio={this.audio}
                  min="0"
                  max={duration}
                  value={currentTime}
                  updateCurrentTime={this.props.updateCurrentTime}
                />
              )}
            </ProgressBar>
          </MetaInfoWrapper>
        </ControlsAndOther>
        <VolumeControl
          audio={this.audio}
          volume={this.props.volume}
          setVolume={this.props.setVolume}/>
      </MainPlayerContainer>
    )
  }
}

MainPlayer.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trackInfo: PropTypes.object.isRequired,
  duration: PropTypes.number.isRequired,
  currentTime: PropTypes.number.isRequired,
  playlist: PropTypes.array.isRequired,
  mainPlayerState: PropTypes.bool.isRequired,
}

export default MainPlayer
