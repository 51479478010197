import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Styled from 'styled-components'
import { connect } from 'react-redux'
import { playPause } from '../../store/audioPlayer/actions'
import { addTrack, removeTrack } from '../../store/playlist/actions'
import Player from './player'

import DownloadIcon from '../../icons/download-icon.svg'
import PlaylistIcon from '../../icons/add-to-playlist.svg'
import AddedToPlaylist from '../../icons/added-icon.svg'
import InfoIcon from '../../icons/info-icon.svg'

const Card = Styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: ${109/16}rem;
  background: #FFFFFF;
  box-shadow: 0 3px 7px -1px rgba(162,141,173,0.80);
  @media screen and (min-width: 576px) {
    margin: ${30/16}rem 0 0;
    width: ${375/16}rem;
  }
`
const Icons = Styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 11px;
  padding-bottom: 11px;
`
const Name = Styled.div`
  display: flex;
  font-weight: 600;
  text-align: center;
  font-size: ${18/16}rem;
  padding-bottom: ${10/16}rem;
  flex-wrap: wrap;
  justify-content: center;
  @media screen and (min-width: 576px) {
    padding-bottom: ${19/16}rem;
  }
`
const Info = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Category = Styled.span`
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-size: ${14/16}rem;
  text-align: center;
  padding: 0 ${26/16}rem;
  word-break: break-all;
`
const AddToPlaylist = Styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 24px;
  cursor: pointer;
  &:hover #voice-actor-stopped-white {
    stroke: #632A6D;
  }
`
const TalentInfo = Styled(AddToPlaylist)`
  ${props => props.isOpen ?
    `
      .icon-text {
        fill: #632A6D;
      }
      .icon-border {
        fill: #c5b8cf;
      }
    ` : null
  }
  &:hover {
    .icon-text {
      fill: #632A6D;
    }
    .icon-background {
      stroke: #632A6D;
    }
  }
`
const RemoveFromPlaylist = Styled(AddToPlaylist)`
`
const DownloadAudioFile = Styled(AddToPlaylist)`
  &:hover #DownloadIcon #download-icon {
    & #Rectangle-2, & #Triangle-3 {
      fill: #632A6D;
    }
  }
`
const ExtraInfo = Styled.div`
  width: 100%;
  border-radius: 3px;
  margin: 4px 0 4px 4px;
  background-color: #c5b8cf;
  font-weight: 800;
  display: flex;
  padding: 12px 24px;
  font-size: ${13/16}rem;
  justify-content: space-around;
  flex-direction: column;
  @media screen and (min-width: 576px) {
    font-size: ${16/16}rem;
  }
`
const EInfoLabel = Styled.span`
  font-weight: 300;
`
const AgentInfoLink = Styled.a`
  &:hover {
    text-decoration: underline;
  }
`

class TalentCard extends Component {
  state = {
    infoIsOpen: false,
  }
  constructor(props) {
    super(props)
    this.toggleInfoState = this.toggleInfoState.bind(this)
    this.url = new URL(this.props.url)
  }
  toggleInfoState() {
    if (this.state.infoIsOpen) {
      this.setState({
        infoIsOpen: false
      })
    } else {
      this.setState({
        infoIsOpen: true
      })
    }
  }
  render() {
    const {
      isPlaying,
      isLoading,
      currentAudioID,
      playPause,

      removeTrack,
      addTrack,
      list,

      agent_email,
      agent_first_name,
      agent_last_name,
      agent_phone,

      first_name,
      last_name,
      category_type,
      // url,
      id,
    } = this.props
    return (
      <Card>
        { !this.state.infoIsOpen ? (
          <>
            <Player
              isPlaying={
                id === currentAudioID &&
                isPlaying
              }
              isLoading={
                id === currentAudioID &&
                isLoading
              }
              onClick={() => playPause(id)}
            />
            <Info>
              <Name>
                <span>{first_name.trim()}&nbsp;</span>
                <span>{last_name.trim()}</span>
              </Name>
              <Category>{category_type.trim()}</Category>
            </Info>
          </>
          ) : (
            <ExtraInfo>
              <span>
                <EInfoLabel>Agent:</EInfoLabel> {agent_first_name.trim()}&nbsp;{agent_last_name.trim()}
              </span>
              <AgentInfoLink href={'mailto:' + agent_email.trim()}>
                {agent_email.trim()}
              </AgentInfoLink>
              <AgentInfoLink href={'tel:' + agent_phone.trim()}>
                {agent_phone.trim()}
              </AgentInfoLink>
            </ExtraInfo>
          )
        }
        <Icons>
          <TalentInfo isOpen={this.state.infoIsOpen} onClick={this.toggleInfoState}>
            <InfoIcon />
          </TalentInfo>
          { list.find(e => e === id) !== undefined ? (
              <RemoveFromPlaylist onClick={() => removeTrack(id)}>
                <AddedToPlaylist />
              </RemoveFromPlaylist>
            ) : (
              <AddToPlaylist onClick={() => addTrack(id)}>
                <PlaylistIcon />
              </AddToPlaylist>
            )
          }
          <DownloadAudioFile href={'https://admin.ncatalent.com/wp-json/ao/v1/download?file=' + this.url.pathname.substr(1)} download="true">
            <DownloadIcon id="DownloadIcon" />
          </DownloadAudioFile>
        </Icons>
      </Card>
    )

  }
}

TalentCard.propTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  category_type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

const mapStateToProps = ({
  playlist: {
    list,
  },
  audioPlayer: {
    currentAudioID,
    isPlaying,
    isLoading,
  }
}) => ({
  list,
  isPlaying,
  isLoading,
  currentAudioID,
})

const mapDispatchToProps = dispatch => ({
  playPause: id => dispatch(playPause(id)),
  addTrack: id => dispatch(addTrack(id)),
  removeTrack: id => dispatch(removeTrack(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(TalentCard)
