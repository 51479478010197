import React, { Component } from "react";
import Styled from "styled-components";

export const Input = Styled.input`
  background: transparent;
  border: 1px solid #C5B8CF;
  border-radius: 3px;
  font-weight: 300;
  font-size: 14px;
  color: #632A6D;
  letter-spacing: 0;
  text-align: left;
  line-height: 24px;
  padding: 5px 18px;
  width: 100%;
`;

export default class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.handleSearch = this.handleSearch.bind(this);
    this.search = React.createRef();
  }
  handleSearch() {
    if (this.search.current.value.length !== 0) {
      this.props.findBySearch(this.search.current.value);
    } else {
      this.props.findBySearch("");
      this.props.setLetterSearch(true);
      this.props.sortByLetter(this.props.sortLetter);
    }
  }
  render() {
    return (
      <Input
        onChange={this.handleSearch}
        ref={this.search}
        placeholder={this.props.lookup || "Search Actors..."}
        type="text"
        value={this.props.lookup}
      />
    );
  }
}
