import React from 'react'
import Styled from 'styled-components'
import InfoContainer from './playlist-talent-info'

import InfoIcon from '../../../icons/info-icon.svg'
import RemoveIcon from '../../../icons/cancel-icon.svg'
import PlayIcon from '../../../icons/dark-play-icon.svg'
import Pause from '../../../icons/pause.svg'

const Item = Styled.li`
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid white;
  background-color: #C5B8CF;
  padding: 0 ${20/16}rem;
  height: ${40/16}rem;
  @media screen and (min-width: 576px) {
    height: ${60/16}rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 ${87/16}rem;
  }
`
const Name = Styled.span`
  font-size: ${12/16}rem;
  font-weight: 600;
  min-width: 26%;
  @media screen and (min-width: 576px) {
    font-size: ${16/16}rem;
  }
  @media screen and (min-width: 992px) {
    font-size: ${20/16}rem;
  }
`
const Type = Styled.span`
  display: none;
  @media screen and (min-width: 992px) {
    display: inline-block;
    font-family: 'Libre Baskerville';
    font-size: ${18/16}rem;
    font-style: italic;
    min-width: ${300/16}rem;
  }
`
const Buttons = Styled.span`
  display: flex;
  align-items: center;
`
const TalentInfo = Styled(InfoIcon)`
  cursor: pointer;
  &:hover {
    .icon-text {
      fill: #632A6D;
    }
    .icon-background {
      stroke: #632A6D;
    }
  }
  .icon-text {
    fill: ${props => props.isOpen ? 'white' : '#a28dad'};
  }
  .icon-background {
    fill: ${props => props.isOpen ? 'rgba(99, 42, 109, 0.2)' : 'none'};
  } 
`
const Remove = Styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: ${13/16}rem;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: none;
  color: #a28dad;
  margin-right: 5px;
  &:hover {
    color: #632A6D;
    #remove-icon {
      .icon-text {
        fill: #632A6D;
      }
      .icon-background {
        stroke: #632A6D;
      }
    }
  }
  @media screen and (min-width: 576px) {
    margin-right: 34px;
  }
`
const Info = Styled(Remove)`
  &:hover {
    color: #632A6D;
    ${TalentInfo} {
      .icon-text {
        fill: #632A6D;
      }
      .icon-background {
        stroke: #632A6D;
      }
    }
  }
`
const Player = Styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg#pause {
    height: 18px;
    width: 11px;
    #pause-icon {
      fill: #A28DAD;
    }
  }
  &:hover {
    svg#pause #pause-icon {
      fill: #632A6D;
    }
    #fullsize-playlist-item {
      fill: #632A6D;
    }
  }
`
const InfoText = Styled.span`
  display: none;
  @media screen and (min-width: 576px) {
    display: inline-block;
  }
`
const playlistItem = (props) => {
  const {
    isPlaying,
    // isLoading,
    playPause,

    toggle,
    isOpen,
    first_name,
    last_name,
    category_type,
    remove,
    id,
  } = props
  return (
    <div>
      <Item>
        <Player onClick={playPause}>
          {isPlaying ? <Pause /> : <PlayIcon />}
        </Player>
        <Name>{ first_name.trim() }&nbsp;{ last_name.trim() }</Name>
        <Type>{ category_type.trim() }</Type>
        <Buttons>
          <Info onClick={() => toggle(id)}>
            <TalentInfo isopen="{ isOpen }" />
            <InfoText>INFO</InfoText>
          </Info>
          <Remove onClick={ remove }>
            <RemoveIcon />
            <InfoText>REMOVE</InfoText>
          </Remove>
        </Buttons>
      </Item>
      <InfoContainer open={ isOpen } id={ id } />
    </div>
  )
}

export default playlistItem