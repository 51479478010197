import { connect } from 'react-redux'
import { 
  playPause,
  isReady,
  loading,
  stopPlaying,
  startPlaying,
  updateDuration,
  updateCurrentTime,
  closePlayer,
  setVolume,
} from '../store/audioPlayer/actions'

import { 
  togglePlaylist
} from '../store/playlist/actions'

import MainPlayer
  from '../components/voice-demos/main-player'

const getTrackInfo = (id, list) => {
  if (id !== null) {
    return list[id]
  }
  return {}
}

const mapStateToProps = ({
  allVoiceTalents,
  audioPlayer: { 
    isPlaying,
    isLoading,
    duration,
    currentTime,
    currentAudioID,
    mainPlayerState,
    volume,
  },
  playlist: {
    list,
    playlistState,
  }
}) => ({
  trackInfo: getTrackInfo(currentAudioID, allVoiceTalents.list) || {},
  isLoading,
  isPlaying,
  duration,
  currentTime,
  mainPlayerState,
  playlist: list,
  playlistState,
  volume
})

const mapDispatchToProps = (dispatch) => ({
  playPauseHandler: () => dispatch(playPause()),
  stopPlaying: () => dispatch(stopPlaying()),
  startPlaying: () => dispatch(startPlaying()),
  isReady: () => dispatch(isReady()),
  loading: () => dispatch(loading()),
  updateDuration: (dur) => dispatch(updateDuration(dur)),
  updateCurrentTime: (time) => dispatch(updateCurrentTime(time)),
  togglePlaylist: () => dispatch(togglePlaylist()),
  closePlayer: () => dispatch(closePlayer()),
  setVolume: (vol) => dispatch(setVolume(vol))
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(MainPlayer)
