import React from 'react'
import Styled from 'styled-components'

import {Button as Btn} from './filter'
const Button = Styled(Btn)`
  display: none;
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: ${props => props.hasCount ? 'space-between' : 'center'};
    align-items: center;
    color: white;
    padding-top: 0;
    padding-bottom: 0;
    height: ${54/16}rem;
    min-width: ${218/16}rem;
    font-size: ${18/16}rem;
    letter-spacing: 4.59px;
    cursor: pointer;
    margin-left: 0;
    &:hover {
      background-color: #763b82;
    }
    &.isActive {
      box-shadow: inset 3px 3px 8px -1px rgba(99,42,109,1);
      background-color: #C5B8CF;
      color: #632A6D;
      border: none;
    }
  }
  @media (min-width: 768px) {
    display: flex;
  }
`
const Indicator =  Styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: ${18/16}rem;
  font-weight: 800;
  width: 35px;
  height: 35px;
  background: #F17A30;
  color: #632A6D;
  border-radius: 50%;
  letter-spacing: 0;
  
`
export default ({
  count,
  onClick,
  state,
}) => (
  <Button className={state ? 'isActive' : null} onClick={onClick} hasCount={ count > 0 } outline>
    <span>Playlist</span>{ count > 0 ? (<Indicator>{count}</Indicator>) : null }
  </Button>
)
