import { 
  LOADING,
  READY,
  PLAY,
  PLAYPAUSE,
  STOP,
  UPDATE_DURATION,
  UPDATE_CURRENT_TIME,
  SET_VOLUME,
  CLOSEPLAYER } from "./actionTypes";

export const loading = () => ({
  type: LOADING,
})
export const isReady = () => ({
  type: READY,
})
export const startPlaying = () => ({
  type: PLAY,
})
export const playPause = (id) => ({
  type: PLAYPAUSE,
  payload: id,
})
export const stopPlaying = (id) => ({
  type: STOP,
  payload: id,
})
export const updateDuration = (duration) => ({
  type: UPDATE_DURATION,
  payload: duration,
})
export const updateCurrentTime = (currentTime) => ({
  type: UPDATE_CURRENT_TIME,
  payload: currentTime,
})
export const closePlayer = () => ({
  type: CLOSEPLAYER,
})
export const setVolume = (vol) => ({
  type: SET_VOLUME,
  payload: vol,
})