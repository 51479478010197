import React, { Component } from "react";
import Styled from "styled-components";

const SeekBar = Styled.div`
  transition: width .05s linear;
  position: relative;
  background-color: #FF6600;
  background: -webkit-linear-gradient(top, #ffffff 0%,#ff6600 10%,#ff6600 86%,#bf5f00 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffffff 0%,#ff6600 10%,#ff6600 86%,#bf5f00 100%); /* W3C, IE10+, FF16+, Chrome26+ */
  width: ${props => props.width}%;
  height: 100%;
  &:after {
    position: absolute;
    right: -9px;
    top: -6px; 
    content: '';
    width: 18px;
    height: 18px;
    display: block;
    background: #E84640;
    border: 1px solid #E84640;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.21);
    border-radius: 50%;
  }
`;
const SeekerContainer = Styled.div`
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.50);
  border-radius: 3px;
  display:block;
  width: 100%;
  height: ${8 / 16}rem;
  &:hover ${SeekBar}:after {
      border: 4px solid #E84640;
      background-color: #b93631;
    }
  }
`;
export default class SeekerBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: 0,
      dragStatus: false
    };
    this.seeker = React.createRef();

    this.setSeekerTime = this.setSeekerTime.bind(this);
    this.mouseDownFn = this.mouseDownFn.bind(this);
    this.mouseUpFn = this.mouseUpFn.bind(this);
    this.mouseMoveFn = this.mouseMoveFn.bind(this);
  }
  mouseDownFn(event) {
    this.setState({ dragStatus: true });
    this.setSeekerTime(event);
  }
  mouseUpFn(event) {
    if (this.state.dragStatus) {
      this.setState({ dragStatus: false });
      this.setSeekerTime(event);
    }
  }
  mouseMoveFn(event) {
    if (this.state.dragStatus) {
      this.setSeekerTime(event);
    }
  }
  setSeekerTime(event) {
    const _this = this.seeker.current;
    event.persist();
    const offset = this.seeker.current.getBoundingClientRect();
    const x = event.pageX - offset.left;
    const time =
      (parseFloat(x) / parseFloat(_this.offsetWidth)) * this.props.max;
    this.props.audio.current.currentTime = time;
    this.props.updateCurrentTime(time);
  }

  static getDerivedStateFromProps(props, state) {
    const currentTime = (props.value / props.max) * 100;
    return {
      currentTime
    };
  }
  render() {
    const { currentTime } = this.state;
    return (
      <SeekerContainer
        onMouseMove={this.mouseMoveFn}
        onMouseDown={this.mouseDownFn}
        onMouseUp={this.mouseUpFn}
        ref={this.seeker}
        onClick={this.setSeekerTime}>
        <SeekBar width={currentTime} />
      </SeekerContainer>
    );
  }
}
