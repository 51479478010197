import React, { Component } from "react";
import Styled from "styled-components";
import PropTypes from "prop-types";

import TalentCard from "./talent-card";
import Filter from "./filter";
import TalentFooter from "./talent-footer";

const Container = Styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin: 15px -24px 0;
  @media screen and (min-width: 576px) {
    margin: 0;
  }
  @media screen and (min-width: 1367px) {
    justify-content: space-between;
  }
`;
class VoiceDemos extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string
  };
  componentDidMount() {
    const tempArr = [];
    tempArr["cat_0"] = {
      id: 0,
      type: "All Categories"
    };
    this.props.fetchTalents();
    this.props.fetchCategories();
  }
  render() {
    const { loading, error, sortedList } = this.props;
    if (loading) {
      return <div>Loading...</div>;
    }
    if (error) {
      return <div>{error}</div>;
    }
    return (
      <>
        <Filter {...this.props} />
        <Container>
          {Object.values(sortedList).map(talent => (
            <TalentCard key={talent.id} {...talent} />
          ))}
        </Container>
        <TalentFooter />
      </>
    );
  }
}

export default VoiceDemos;
