import React from 'react';

import Layout from '../components/layout';
import VoiceDemos from '../containers/voice-demos';
import { Title, Container, Fancy } from '../components/page-styles';

const VoiceReelsDemosPage = () => {
  return (
    <Layout>
      <Container>
        <Title>Voice Reels <Fancy>&</Fancy> Demos</Title>
        <VoiceDemos />
      </Container>
    </Layout>
  );
}

export default VoiceReelsDemosPage;