import React, { Component } from 'react'
import ReactSelect from 'react-select'
import Styled from 'styled-components'

import SearchInput from './search-input'

const Form = Styled.div`
  display: flex;
  margin-top: 15px;
  @media screen and (min-width: 992px) {
    margin-top: 0;
    margin-left: 15px;
    width: 50%;
  }
`
export const Button = Styled.button`
  cursor: pointer;
  outline: none;
  background-color: ${props => props.outline ? 'transparent' : '#632A6D'};
  border: ${props => props.outline ? '1px solid #C5B8CF' : 'none'};
  border-radius: 4px;
  box-shadow: ${props => props.outline ? 'none' : '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)'};
  color: ${props => props.outline ? '#632A6D' : 'white'};
  font-size: 14px;
  letter-spacing: 1.25px;
  line-height: 16px;
  padding: 10px 18px;
  text-align: center;
  text-transform: uppercase;
  min-width: 100px;
  margin-left: 15px;
`;
export const Select = Styled(ReactSelect)`
  width: auto;
`;
export const FormGroup = Styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: ${props => props.col ? props.col : 'auto'};
  & + &, & + ${Form} {
    margin-top: ${15/16}rem;
  }
  @media screen and (min-width: 992px) {
    flex-direction: row;
  }
`;
const FormControl =  Styled.div`
  flex: 1 auto;
`;
const AlphabetSelect = Styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${40/16}rem;
  flex-wrap: wrap;
  @media screen and (min-width: 576px) {
    justify-content: space-between;
    margin-top: ${54/16}rem;
  }
`;
const Alphabet = Styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: ${21/16}rem;
  font-weight: 300;
  cursor: ${({enabled}) => enabled ? 'pointer' : 'not-allowed'};
  color: ${({enabled}) => enabled ? '#632A6D' : '#C5B8CF'};

  margin-bottom: 12px;
  margin-left: 6px;
  margin-right: 6px;
  @media screen and (min-width: 576px) {
    margin: 0;
  }  
  &.isActive {
    background-color: ${({enabled}) => enabled ? '#632A6D' : 'none'};
    color: ${({enabled}) => enabled ? 'white' : '#C5B8CF'};
  }
  @media (min-width: 1000px) {
    margin-top: 0;
    margin-right: 0;
  }
`;

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

class Filter extends Component {
  constructor(props) {
    super(props)
    this.search = React.createRef()
    this.sortByLetter = this.sortByLetter.bind(this)
    this.categoryChange = this.categoryChange.bind(this)
    this.genderChange = this.genderChange.bind(this)
  }
  categoryChange(newCat) {
    if (newCat.value === '' && this.props.currentCategoryOption.value === '') {
      this.props.setLetterSearch(true)
    } else {
      this.props.setLetterSearch(false)
      this.props.setCurrentCat(newCat)
    }
  }
  genderChange(newGen) {
    if (newGen.value === '' && this.props.currentGenderOption === '') {
      this.props.setLetterSearch(true)
    } else {
      this.props.setLetterSearch(false)
      this.props.setCurrentGen(newGen)
    }
  }
  sortByLetter(letter) {
    if (this.props.letterSorterStatus === true) {
      this.props.sortByLetter(letter)
    } else {
      return
    }
  }
  clearFilter() {
    this.props.findBySearch('')
  }
  render() {
    return (
      <>
        <div>
          <FormGroup>
            <FormControl>
              <SearchInput {...this.props}/>
            </FormControl>
          </FormGroup>
          <FormGroup>
            <FormControl>
              <Select
                placeholder={this.props.currentCategoryOption}
                value={this.props.currentCategoryOption}
                options={this.props.categoryOptions}
                onChange={this.categoryChange}
              />
            </FormControl>
            <Form>
              <FormControl >
                <Select 
                  placeholder={this.props.currentGenderOption}
                  value={this.props.currentGenderOption}
                  options={this.props.genderOptions}
                  onChange={this.genderChange}
                />
              </FormControl>
              <Button onClick={this.props.clearFilter} outline>Clear</Button>
            </Form>
          </FormGroup>
        </div>
        <AlphabetSelect>
          { alphabet.map((letter) => {
            const lcLetter = letter.toLowerCase()
            return (
              <Alphabet
                enabled={this.props.letterSorterStatus}
                className={this.props.sortLetter === lcLetter ? 'isActive' : 'inActive'}
                onClick={() => this.sortByLetter(lcLetter)}
                key={lcLetter}>
                {letter}
              </Alphabet>
            )
          }
          )}
        </AlphabetSelect>
      </>
    )
  }
}

export default Filter