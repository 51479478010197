import React, { Component } from "react";
import Styled from "styled-components";

import VolumeIcon from "../../icons/sound-icon.svg";
import VolumeMuteIcon from "../../icons/sound-mute-icon.svg";

const VolumeController = Styled.div`
  cursor: pointer;
  position: relative;
  height: 20px;
  display: flex;
  align-items: center;
  &:before {
    content: '';
    width: 110px;
    height: 2px;
    background-color: white;
    display: block;
  }
`;
const Volumeknob = Styled.div`
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  left: ${props => props.volume}px;
`;
const VolumeContainer = Styled.div`
  display: none;
  justify-content: center;
  align-items: flex-end;
  max-width: ${142 / 16}rem;
  margin-left: ${50 / 16}rem;
  @media screen and (min-width: 1100px) {
    display: flex;
  }
`;
const Icon = Styled(VolumeIcon)`
  margin-right: ${12 / 16}rem;
  flex-shrink: 0;
`;
const IconMute = Styled(VolumeMuteIcon)`
  margin-right: ${12 / 16}rem;
  flex-shrink: 0;
`;
const VolumeInner = Styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const MuteButton = Styled.div`
  cursor: pointer;
  &:hover {
    #speaker {
      fill: #ab7db3;
    }
    #sound-3 {
      stroke: #ab7db3;
    }
    #sound-2 {
      stroke: #ab7db3;
    }
    #Oval {
      fill: #ab7db3;
    }
    #Rectangle {
      fill: #ab7db3;
    }
  }
`;
export default class VolumeControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      volume: 50,
      dragStatus: false
    };
    this.volume = React.createRef();
    this.changeVolume = this.changeVolume.bind(this);
    this.mouseDownFn = this.mouseDownFn.bind(this);
    this.mouseUpFn = this.mouseUpFn.bind(this);
    this.mouseMoveFn = this.mouseMoveFn.bind(this);
    this.mute = this.mute.bind(this);
  }
  mute() {
    this.props.setVolume(0);
  }
  mouseDownFn(event) {
    this.setState({ dragStatus: true });
    this.changeVolume(event);
  }
  mouseUpFn(event) {
    if (this.state.dragStatus) {
      this.setState({ dragStatus: false });
      this.changeVolume(event);
    }
  }
  mouseMoveFn(event) {
    if (this.state.dragStatus) {
      this.changeVolume(event);
    }
  }
  changeVolume(event) {
    const _this = this.volume.current;
    event.persist();
    const offset = this.volume.current.getBoundingClientRect();
    const x = event.pageX - offset.left;
    const time = (parseFloat(x) / parseFloat(_this.offsetWidth)) * 100;

    this.props.audio.current.volume = time / 100;
    this.props.setVolume(time);
  }
  render() {
    return (
      <VolumeContainer>
        <VolumeInner>
          <MuteButton>
            {this.props.volume === 0 ? <IconMute /> : <Icon />}
          </MuteButton>
          <VolumeController
            onMouseDown={this.mouseDownFn}
            onMouseMove={this.mouseMoveFn}
            onMouseUp={this.mouseUpFn}
            ref={this.volume}>
            <Volumeknob volume={this.props.volume} />
          </VolumeController>
        </VolumeInner>
      </VolumeContainer>
    );
  }
}
