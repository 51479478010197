import { connect } from 'react-redux'
import {
  fetchTalents,
  fetchCategories,
  findBySearch,
  setDisplayList,
  sortByLetter,
  setCurrentCat,
  setCurrentGen,
  setLetterSearchStatus,
  clearFilter,
} from '../store/voice-demos/actions'
import VoiceDemos from '../components/voice-demos'

const filterByLetter = (list, sortLetter) => Object.values(list).filter(talent => 
  talent.last_name.slice(0, 1).trim().toLowerCase() === sortLetter
)

const mapStateToProps = ({ allVoiceTalents }) => {
  const {
    currentCategoryOption,
    letterSorterStatus,
    currentGenderOption,
    sortLetter,
    lookup,
    list,
  } = allVoiceTalents

  let filtered = (() => {
    const catID = currentCategoryOption.value
    const genID = currentGenderOption.value

    if (letterSorterStatus) {
      return filterByLetter(list, sortLetter)
    }
    
    else if (catID !== '' || genID !== '') {
      return Object.values( list ).filter( a => {
        if (catID === '') {
          return a.gender === genID
        }
        if (genID === '') {
          return a.category_id === catID
        }
        
        return a.category_id === catID && a.gender === genID
      })
    }
    else {
      return list
    }
  })()
  
  if (lookup.length !== 0) {
    const currentValue = lookup
    const cvLength = lookup.length
    const currentCompare = currentValue.slice(0, cvLength).toLowerCase()
    filtered = Object.values(filtered).filter(talent => {
      const lastNameSlice = talent.last_name.slice(0, cvLength).trim().toLowerCase()
      const firstNameSlice = talent.first_name.slice(0, cvLength).trim().toLowerCase()
      return currentCompare === lastNameSlice || currentCompare === firstNameSlice
    })
  }

  let sortedList = filtered.sort( ( a, b ) => {
    const aLastName = a.last_name.toLowerCase().trim()
    const bLastName = b.last_name.toLowerCase().trim()
  
    if (aLastName < bLastName) {
      return -1
    }
  
    if (aLastName > bLastName) {
      return 1
    }
  
    return 0
  } )

  return {
    ...allVoiceTalents, 
    sortedList,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCategories: () => dispatch(fetchCategories()),
  fetchTalents: () => dispatch(fetchTalents()),
  clearFilter: () => dispatch(clearFilter()),
  findBySearch: lookup => dispatch(findBySearch(lookup)),
  setLetterSearch: status => dispatch(setLetterSearchStatus(status)),
  setList: list => dispatch(setDisplayList(list)),
  sortByLetter: letter => dispatch(sortByLetter(letter)),
  setCurrentCat: cat => dispatch(setCurrentCat(cat)),
  setCurrentGen: gen => dispatch(setCurrentGen(gen)),
})

export default connect(mapStateToProps, mapDispatchToProps)(VoiceDemos)