import React from 'react'
import { connect } from 'react-redux'
import Styled from 'styled-components'

const Container = Styled.div`
  transition: max-height .8s;
  ${props => props.open ? `
    max-height: 1000px;
    height: auto;
    width: 100%;
    background: #e7e3eb;
    color: white;
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 15px;
    @media screen and (min-width: 992px) {
      padding-left: 92px;
    }
  ` :
  `
    height: 0;
    visibility: hidden;
    max-height: 0;
  `
  }
`
const InnerContaienr = Styled.div`
  transition: opacity .8s;
  opacity: ${props => props.open ? `1` : `0`};
  display: flex;
  height: 100%;
  /* padding-left: 32px; */
  color: #632A6D;
  font-size: 14px;
  flex-direction: column;
  @media screen and (min-width: 660px) {
    flex-direction: row;
  }
  @media screen and (min-width: 900px) {
    font-size: 20px;
  }
  &:before {
    content: '';
    display: block;
    background-image: linear-gradient(-180deg, #A28DAD 0%, rgba(197,184,207,0.00) 100%);
    min-height: 100%;
    width: 5px;
    margin-right: 32px;
    border-radius: 3px;
  }
`
const Label = Styled.span`
  font-weight: 300;
  margin-right: 5px;
`
const Text = Styled.span`
  font-weight: 500;
  &:hover a {
    text-decoration: underline;
  }
`
const Info = Styled.div`
  margin-bottom: 5px;
`
const InfoWrapper = Styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 660px) {
    margin-right: 118px;
  }
  &:last-of-type {
    margin-right: 0;
  }
`
const PlaylistTalentInfo = ({
  first_name,
  last_name,
  gender,
  agent_first_name,
  agent_last_name,
  agent_phone,
  agent_email,
  category_type,
  open,
}) => {
  return (
    <Container open={open}>
      <InnerContaienr open={open}>
        <InfoWrapper>
          <Info>
            <Label>Actor:</Label>
            <Text>{first_name + ' ' + last_name}</Text>
          </Info>

          <Info>
            <Label>Gender:</Label>
            <Text>{gender === 'M' ? 'Male' : 'Female'}</Text>
          </Info>

          <Info>
            <Label>Category:</Label>
            <Text>{category_type}</Text>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Info>
            <Label>Agent:</Label>
            <Text>{agent_first_name + ' ' + agent_last_name}</Text>
          </Info>

          <Info>
            <Label>Agent Phone Number:</Label>
            <Text><a href={'tel:'+ agent_phone}>{agent_phone}</a></Text>
          </Info>

          <Info>
            <Label>Agent Email:</Label>
            <Text><a href={'mailto:'+ agent_email}>{agent_email}</a></Text>
          </Info>
        </InfoWrapper>
      </InnerContaienr>
    </Container>
  )
}

const mapStateToProps = (state, props) => ({
  ...Object.values(state.allVoiceTalents.list).filter(item => props.id === item.id)[0]
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistTalentInfo)
