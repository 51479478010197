import React, {Component} from 'react'
import Styled from 'styled-components'

import InfoIcon from '../../../icons/info-icon.svg'

const TalentInfoContainer = Styled.div`
  display: none;
  @media screen and (min-width: 576px) {  
    display: block;
    position: relative;
    cursor: pointer;
    margin-right: ${12/16}rem;
    &:hover {
      .icon-text {
        fill: white;
      }
      .icon-background {
        stroke: white;
      }
    }
    .icon-text {
      fill: ${props => props.open ? '#632A6D!important': '#A28DAD'};
    }
    .icon-background {
      stroke: ${props => props.open ? '#c5b8cf!important': '#c5b8cf'};
    }
    .icon-border {
      fill: ${props => props.open ? '#c5b8cf!important': 'none'};
    }
  }
`
const TalentLabel = Styled.span`
  color: white;
  color: #632A6D;
`
const TalentDisplay = Styled.div`
  display: ${props => props.open ? 'flex': 'none'};
  position: absolute;
  top: -184px;
  left: -72px;
  width: 289px;
  height: 170px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(83, 39, 91, 0.58);
  background-color: #c5b8cf;
  padding: 16px;
  font-size: 14px;
  color: white;
  letter-spacing: 0;
  flex-direction: column;
  justify-content: space-between;
  &:after {
    content: '';
    display: ${props => props.open ? 'block': 'none'};
    position: absolute;
    left: 28.2%;
    bottom: -15px;
    width: 5px;
    height: 15px;
    background-color: #c5b8cf;
  }
`
const Hr = Styled.hr`
  margin: 0;
  background: rgba(162, 141, 173, 0.3);
`
const AgentInfoLink = Styled.a`
  color: white;
  &:hover {
    text-decoration: underline;
  }
`
export default class TalentInfo extends Component {
  constructor(props) {
    super(props)
    this.toggleTalentInfo = this.toggleTalentInfo.bind(this)
    this.state = {
      talentInfoState: false
    }
  }
  toggleTalentInfo() {
    this.setState({
      talentInfoState: !this.state.talentInfoState,
    })
  }
  render() {
    const { trackInfo } = this.props
    return (
      <TalentInfoContainer open={this.state.talentInfoState}>
        <TalentDisplay open={this.state.talentInfoState}>
          <span>
            <TalentLabel>Gender:</TalentLabel> {trackInfo.gender === 'M' ? 'Male' : 'Female'}
          </span>
          <span>
            <TalentLabel>Category:</TalentLabel> {trackInfo.category_type}
          </span>
          <Hr />
          <span>
            <TalentLabel>Agent:</TalentLabel> {trackInfo.agent_first_name} {trackInfo.agent_last_name}
          </span>
          <AgentInfoLink href={'tel:' + trackInfo.agent_phone}>{trackInfo.agent_phone}</AgentInfoLink>
          <AgentInfoLink href={'mailto:' + trackInfo.agent_email}>{trackInfo.agent_email}</AgentInfoLink>
        </TalentDisplay>
        <InfoIcon onClick={this.toggleTalentInfo} />
      </TalentInfoContainer>
    )
  }
}
