import React from 'react'
import PropTypes from 'prop-types'
import Styled, { ThemeProvider } from 'styled-components'

import PlayIcon from '../../icons/play.svg'
import PauseIcon from '../../icons/pause.svg'

const playerTheme = {
  dim: `${52/16}rem`,
  color: 'rgba(99,42,109,0.19)',
  hoverColor: '#87558F'
}
const altTheme = {
  dim: `${60/16}rem`,
  color: 'white',
  hoverColor: 'white'
}
const Play = Styled(PlayIcon)`
  #play-icon {
    fill: ${({theme}) => theme.color}
  }
  @media (min-width: 768px) {
    margin-left: 5px;    
  }
`
const Pause = Styled(PauseIcon)`
  #pause-icon {
    fill: ${({theme}) => theme.color}
  }
`
export const Controls = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({theme}) => theme.dim};
  margin-left: ${14/16}rem;
  @media (min-width: 745px) {
    margin-left: ${26/16}rem;
  }
`
export const PlayButton = Styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({theme}) => theme.dim};
  height: ${({theme}) => theme.dim};
  border-radius: 50%;
  border: none;
  background: transparent;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    #play-icon {
      fill: ${({theme}) => theme.hoverColor}
    }
    #pause-icon {
      fill: ${({theme}) => theme.hoverColor}
    }
  }
  @media (min-width: 768px) {
    border: 1px solid ${({theme}) => theme.color};
    width: ${({theme}) => theme.dim};
    height: ${({theme}) => theme.dim};
  }
`

const Player = ({
  mainPlayer,
  isPlaying,
  isLoading,
  onClick,
}) => (
  <ThemeProvider theme={
    mainPlayer ? 
    altTheme : 
    playerTheme
  }>
    <Controls>
      <PlayButton
        type="button"
        play={isPlaying}
        onClick={onClick}
      >
      { isPlaying || isLoading ?
        <Pause /> :
        <Play />
      }
      </PlayButton>
    </Controls>
  </ThemeProvider>
)

Player.defaultProps = {
  mainPlayer: false,
  isPlaying: false,
  isLoading: false,
  onClick: () => console.error('Please Attach "onClick" handler to Player Component.'),
}
Player.propTypes = {
  mainPlayer: PropTypes.bool,
  isLoading: PropTypes.bool,
  isPlaying: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Player
