import React, {Component} from 'react'
import Styled from 'styled-components'

import PlaylistItem from './playlist-item'

import {connect} from 'react-redux'
import {closePlayer, playPause} from '../../store/audioPlayer/actions'
import {clearPlaylist, removeTrack, togglePlaylist,} from '../../store/playlist/actions'
import DownChevron from '../../icons/down-chevron-white.svg'

const PlayList = Styled.div`
  transition: all .1s ease;
  display: block;
  max-height: ${props => props.show ? '500px' : '0'};
  width: 100%;
`
const PlaylistFooter = Styled.footer`
  display: flex;
  justify-content: space-between;
  background-color: #A28DAD;
  padding: 0 ${20/16}rem;
  height: ${40/16}rem;
  color: white;
  align-items: center;
  font-size: ${12/16}rem;
  > a {
    text-decoration: underline;
    color: white;
  }
  @media screen and (min-width: 576px) {
    font-size: ${18/16}rem;
    padding: 0 ${92/16}rem;
    height: ${60/16}rem;
  }
`
const PlayListHeader = Styled.div`
  cursor: pointer;
  background-color: #a28dad;
  padding: 0 20px;
  display: flex;
  align-items: center;
  height: 33px;
  border-bottom: 1px solid white;
  color: white;
  font-weight: 600;
  font-size: 12px;
  &:hover {
    background-color: #ab98b5;
  }
`
const ClearPlaylist = Styled.button`
  cursor: pointer;
  background: none;
  border: none;
  color: #632A6D;
  font-weight: 800;
  font-size: 13px;
  &:hover {
    text-decoration: underline;
  }
  @media screen and (min-width: 576px) {
    padding: 0 24px;
  }
`
const Download = Styled(ClearPlaylist)`
  color: white;
  font-weight: 400;
  padding: 0;
  text-decoration: underline;
  &:hover {
    color: #632A6D;
  }
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
`
class Playlist extends Component {
  constructor(props) {
    super(props)
    this.state = {
      infoIsOpen: '',
    }
    this.toggleInfoContainer = this.toggleInfoContainer.bind(this)
    this.getZipFile = this.getZipFile.bind(this)
  }
  getZipFile() {
    let filelist = this.props.list.map(file => {
      return new URL(file.url).pathname.substr(1)
    }).join(',')
    const url = 'https://admin.ncatalent.com/wp-json/ao/v1/zip?list='
    const link = document.createElement('a');
    link.href = url + filelist;
    document.body.appendChild(link);
    link.click();
  }
  toggleInfoContainer(id) {
    if (id === this.state.infoIsOpen) {
      this.setState({
        infoIsOpen: ''
      })
    } else {
      this.setState({
        infoIsOpen: id
      })
    }
  }
  render() {
    const {
      state,
      list,
      remove,
      toggle,

      isPlaying,
      isLoading,
      currentAudioID,
      playPause,

      clearPlaylist
    } = this.props

    return (
      <PlayList show={state}>
        <PlayListHeader onClick={toggle}>
          <DownChevron style={{marginRight: 16}}/>
          <span>PLAYLIST</span>
        </PlayListHeader>
        <ul style={{margin: 0, padding: 0}}>
          {list.map(e =>
              <PlaylistItem
                isPlaying={currentAudioID === e.id && isPlaying}
                isLoading={currentAudioID === e.id && isLoading}
                playPause={() => playPause(e.id)}
                isOpen={this.state.infoIsOpen === e.id}
                toggle={this.toggleInfoContainer}
                remove={() => remove(e.id)}
                key={e.id}
                {...e}
              />
            )
          }
        </ul>
        <PlaylistFooter>
          <div>
            <Download onClick={this.getZipFile}>Download</Download>&nbsp;the playlist.
          </div>
          <ClearPlaylist onClick={clearPlaylist}>
            CLEAR PLAYLIST
          </ClearPlaylist>
        </PlaylistFooter>
      </PlayList>
    )
  }
}

const getPlaylist = (list, talents) => {
  const play = []
  list.forEach((id) => {
    play.push(talents[id])
  })
  return play
}
const mapStateToProps = ({
  allVoiceTalents,
  playlist: {
    list,
    playlistState,
  },
  audioPlayer: {
    mainPlayerState,
    isPlaying,
    isLoading,
    currentAudioID,
    playPause,
  },
}) => ({
  state: playlistState,
  list: getPlaylist(list, allVoiceTalents.list),
  mainPlayerState,
  isPlaying,
  isLoading,
  currentAudioID,
  playPause,
})

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(togglePlaylist()),
  remove: (id) => dispatch(removeTrack(id)),
  closePlayer: () => dispatch(closePlayer()),
  playPause: (id) => dispatch(playPause(id)),
  clearPlaylist: () => dispatch(clearPlaylist()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Playlist)
