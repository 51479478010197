import axios from 'axios';
import {
  SET_DISPLAY_LIST,
  SET_CAT_OPTIONS,
  SET_CURRENT_CAT,
  SET_CATEGORIES,
  SORT_BY_LETTER,
  SET_CURRENT_GEN,
  FIND_BY_SEARCH,
  SET_LETTER_SEARCH_STATUS,
  GET_TALENT_BY_ID,
  
  TALENTS_HAVE_ERRORED,
  TALENTS_ARE_LOADING,
  TALENTS_FETCHED_SUCCESSFULLY,
  
  CLEAR_FILTER,
} from './actionTypes';

export const clearFilter = () => ({
  type: CLEAR_FILTER
})
export const setDisplayList = (list) => ({
  type: SET_DISPLAY_LIST,
  payload: {
    ...list
  }
})
export const setCatOptions = (cats) => ({
  type: SET_CAT_OPTIONS,
  payload: cats,
})
export const setCurrentCat = (cat) => ({
  type: SET_CURRENT_CAT,
  payload: cat,
})
export const setCategories = (cats) => ({
  type: SET_CATEGORIES,
  payload: cats,
})
export const sortByLetter = (letter) => ({
  type: SORT_BY_LETTER,
  payload: letter,
})
export const setLetterSearchStatus = (status) => ({
  type: SET_LETTER_SEARCH_STATUS,
  payload: status,
})
export const setCurrentGen = (gen) => ({
  type: SET_CURRENT_GEN,
  payload: gen,
})
export const getTalentById = (id) => ({
  type: GET_TALENT_BY_ID,
  payload: id,
})
export const talentsHaveErrored = error => ({
  type: TALENTS_HAVE_ERRORED,
  payload: error,
})
export const talentsAreLoading = () => ({
  type: TALENTS_ARE_LOADING,
})
export const talentsFetchedSuccessfully = talents => ({
  type: TALENTS_FETCHED_SUCCESSFULLY,
  payload: {
    ...talents
  }
})
export const findBySearch = lookup => ({
  type: FIND_BY_SEARCH,
  payload: lookup,
})

export const fetchCategories = () => {
  return (dispatch) => {
    const tempArr = []
    tempArr['cat_0'] = {
      id: 0,
      type: 'All Categories'
    }
    axios.get('https://admin.ncatalent.com/wp-json/ao/v1/categories')
      .then(response => {
        const options = Object.values(response.data).map(
          ({ category_id, type }) => {
            return {
              value: category_id,
              label: type,
            }
          })

        dispatch(setCatOptions(options))
        dispatch(setCategories(tempArr))
      })
  }
}
export const fetchTalents = () => {
  const ID = prefix => (prefix || "") + Math.random().toString(36).substr(2, 9)
  return (dispatch, getState) => {
    const {allVoiceTalents: { loading }} = getState()
    if (!loading) {
      return
    }
    dispatch(talentsAreLoading())
    
    let tempArr = []
    axios.get('https://admin.ncatalent.com/wp-json/ao/v1/talent')
      .then(response => {
        Object.values(response.data).forEach(
          (value) => {
            const id = ID('audio_')
            tempArr[id] = {...value, id}
          })
          dispatch(talentsFetchedSuccessfully(tempArr))
          dispatch(sortByLetter('a'))
      })
      .catch(error => {
        dispatch(talentsHaveErrored(error.message))
      })
  }
}
