import React from 'react'
import Styled from 'styled-components'
import { connect } from 'react-redux'
import MainPlayer from '../../containers/main-player'
import Playlist from './playlist'
import PlaylistIcon from '../../icons/playlist-icon.svg'
import { togglePlaylist } from '../../store/playlist/actions'

const Footer = Styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  /* height: ${props => (props.show ? 'auto' : '0')}; */
  display: flex;
  flex-direction: column;
  z-index: 500;
`
const FABPlaylist = Styled.div`
  transition: all .3s ease-in-out;
  cursor: pointer;
  width: 157px;
  height: 42px;
  background-color: #632A6D;
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  padding: 7px;
  padding-left: 13px;
  align-items: center;
  border-radius: 3px;
  position: absolute;
  top: -58px;
  /* right: 15px; */
    right: ${props => (props.show ? '15px' : '-169px')};
  /* @media screen and (min-width: 768px) { */
  /* } */
  &:hover {
    background-color: #763b82;
  }
  #playlist-icon {
    stroke: white;
  }
`
const FABText = Styled.span`
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  color: white;
  letter-spacing: 0.9px;
`
const PlaylistCount = Styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  border: 1px solid white;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: #f17a30; 
`
function TalentFooter({
  mainPlayerState,
  playlistState,
  list,
  togglePlaylist,
}) {
  return (
    <Footer plState={playlistState} mpState={mainPlayerState}>
      <FABPlaylist
        show={list.length !== 0 && !mainPlayerState}
        onClick={togglePlaylist}
      >
        <PlaylistIcon />
        <FABText>Playlist</FABText>
        <PlaylistCount>{list.length}</PlaylistCount>
      </FABPlaylist>
      <Playlist />
      <MainPlayer />
    </Footer>
  )
}

const mapStateToProps = ({
  playlist: { playlistState, list },
  audioPlayer: { mainPlayerState },
}) => ({
  playlistState,
  mainPlayerState,
  list,
})

const mapDispatchToProps = dispatch => ({
  togglePlaylist: () => {
    console.log('in')
    return dispatch(togglePlaylist())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalentFooter)
